export const getRandomTextAlign = () => {
  const random = Math.round(Math.random() % 2)
  const alignOptions = ["left", "center", "right"]
  return alignOptions[random]
}

export const getAlbumSlug = albumName => {
  return `/album/${albumName
    .split(" ")
    .join("-")
    .toLowerCase()}`
}

export const formatPhotosForGallery = photos => {
  return photos.map((p, index) => {
    const photoUrl = p.photo.file.asset.fluid.src
    return {
      original: photoUrl,
      thumbnail: photoUrl,
      bulletClass: "customBullet",
    }
  })
}
