import React from "react"
import { graphql } from "gatsby"
import ImageGallery from "react-image-gallery"
import "react-image-gallery/styles/css/image-gallery.css"
import styled from "@emotion/styled"

import { Box, AccentText, Text, Paragraph } from "../helpers/styledComponents"
import Base from "../components/layout/base"
import { formatPhotosForGallery } from "../helpers/utils"
import SEO from "../components/utils/seo"
import theme from "../helpers/theme"

const Album = props => {
  const { name, photos } = props.data.sanityAlbum
  const galleryItems = formatPhotosForGallery(photos)

  return (
    <Base hideSideText={true}>
      <SEO title={name} />
      <Content mt={"20px"}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Paragraph
            css={{
              margin: 0,
              marginRight: "10px",
              fontSize: "12px",
            }}
            lineHeight={[1.2, 1.5]}
            fontSize={["0.75rem", "1.25rem"]}
          >
            {`${"Album".toUpperCase()} /`}
          </Paragraph>
          <AccentText>
            <Text fontSize={[4, 5]}>{name}</Text>
          </AccentText>
        </Box>
        <ImageGalleryContainer mt={[3, 4]} mb={[4, 5]}>
          <ImageGallery
            items={galleryItems}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={true}
            showNav={false}
          />
        </ImageGalleryContainer>
      </Content>
    </Base>
  )
}

export const query = graphql`
  query albumTemplateQuery($id: String!) {
    sanityAlbum(_id: { eq: $id }) {
      name
      photos {
        photo {
          file {
            asset {
              fluid(maxWidth: 3000) {
                src
              }
            }
          }
          uniqueIdentifier
        }
      }
    }
    leftArrow: file(relativePath: { eq: "left-arrow.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rightArrow: file(relativePath: { eq: "right-arrow.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Content = styled(Box)({
  minHeight: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.mediaQueries[2]]: {
    minHeight: "unset",
  },
})

const ImageGalleryContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
  div: {
    width: "100%",
  },
})

export default Album
